type CheckProps = {
  variant?: 'accent' | 'danger';
  className?: string;
}
export function Check({ variant = 'accent', className }: CheckProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path data-variant={variant} className="data-[variant=accent]:fill-accent data-[variant=danger]:fill-danger" d="M6.36669 9.96435L11.945 4.3861C12.0969 4.23417 12.2771 4.1582 12.4857 4.1582C12.6943 4.1582 12.8745 4.23417 13.0265 4.3861C13.1784 4.53804 13.2544 4.71829 13.2544 4.92687C13.2544 5.13544 13.1784 5.31569 13.0265 5.46762L6.9011 11.593C6.74917 11.7449 6.57103 11.8209 6.36669 11.8209C6.16234 11.8209 5.9842 11.7449 5.83227 11.593L2.97357 8.73429C2.82164 8.58235 2.74778 8.4021 2.752 8.19354C2.75624 7.98496 2.83432 7.8047 2.98625 7.65277C3.13819 7.50084 3.31844 7.42487 3.52702 7.42487C3.73559 7.42487 3.91584 7.50084 4.06777 7.65277L6.36669 9.96435Z" />
    </svg>
  )
}