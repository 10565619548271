import clsx from "clsx";

type DotProps = {
  variant?: 'accent' | 'neutral' | 'success' | 'error';
}

export function Dot({ variant }: DotProps) {
  return (
    <div 
      data-variant={variant} 
      className={clsx(
        "data-[variant=accent]:bg-accent data-[variant=error]:bg-danger data-[variant=success]:bg-success-new data-[variant=neutral]:bg-surface",
        "data-[variant=accent]:shadow-dot-accent data-[variant=neutral]:shadow-dot-neutral data-[variant=success]:shadow-dot-success data-[variant=error]:shadow-dot-danger",
        "rounded-full w-2 h-2"
      )} 
    />
  )
}