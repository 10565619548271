import { Analyze } from "./Analyze";
import { Code } from "./Code";
import { SideContent } from "./SideContent";

type ScreensProps = {
  currentTab: 'code' | 'analyze';
}

export function Screens({ currentTab }: ScreensProps) {
  return (
    <div className="flex gap-3 px-3 flex-1 self-stretch items-start overflow-hidden z-40">
      {currentTab === 'code' && <Code />}
      {currentTab === 'analyze' && <Analyze />}
      <SideContent />
    </div>
  )
}
