import { ReactNode } from "react";
import { Dot } from "./Dot";
import clsx from "clsx";

export type FeedbackProps = {
  status: 'accent' | 'neutral' | 'success' | 'error';
  title: string;
  description?: ReactNode;
  actions?: ReactNode;
  className?: string;
}

export function Feedback({ status, title, description, actions, className }: FeedbackProps) {

  return (
    <div className={clsx("flex flex-col text-sm relative font-medium text-font-variant py-3 px-4 items-start gap-2 self-stretch border-2 border-border bg-background rounded-b-lg", className)}>
      <div className="flex items-center gap-[9px]">
        <Dot variant={status} />
        <span className="font-youth">{title}</span>
      </div>
      {description}
      <div className="absolute bottom-3 right-4">
        {actions}
      </div>
    </div>
  )
}
