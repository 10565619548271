type FunctionIconProps = {
  isActive?: boolean;
}

export function FunctionIcon({ isActive }: FunctionIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="shrink-0">
      <mask id="mask0_6374_7276" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6374_7276)">
        <path data-active={isActive} className="data-[active=true]:fill-accent fill-font-variant" d="M9.41292 13.4689V11.9562H11.2376C11.4398 11.9562 11.6101 11.887 11.7484 11.7486C11.8869 11.6103 11.9561 11.4386 11.9561 11.2338V10.0201C11.9561 9.59253 12.079 9.20519 12.3248 8.85808C12.5705 8.51097 12.8961 8.26386 13.3014 8.11675V7.88342C12.8961 7.73631 12.5705 7.48919 12.3248 7.14208C12.079 6.79497 11.9561 6.40764 11.9561 5.98008V4.76242C11.9561 4.56019 11.8869 4.38992 11.7484 4.25158C11.6101 4.11314 11.4398 4.04392 11.2376 4.04392H9.41292V2.53125H11.3691C11.9512 2.53125 12.4467 2.73564 12.8556 3.14442C13.2644 3.55331 13.4688 4.05014 13.4688 4.63492V5.84858C13.4688 6.05081 13.538 6.22175 13.6764 6.36142C13.8148 6.50119 13.9864 6.57108 14.1913 6.57108H14.8021V9.42908H14.1913C13.9864 9.42908 13.8148 9.49831 13.6764 9.63675C13.538 9.77508 13.4688 9.94536 13.4688 10.1476V11.3653C13.4688 11.95 13.2644 12.4469 12.8556 12.8557C12.4467 13.2645 11.9512 13.4689 11.3691 13.4689H9.41292ZM4.63075 13.4689C4.04864 13.4689 3.55314 13.2645 3.14425 12.8557C2.73548 12.4469 2.53109 11.95 2.53109 11.3653V10.1476C2.53109 9.94536 2.46186 9.77508 2.32342 9.63675C2.18509 9.49831 2.01481 9.42908 1.81259 9.42908H1.19775V6.57108H1.81259C2.01481 6.57108 2.18509 6.50119 2.32342 6.36142C2.46186 6.22175 2.53109 6.05081 2.53109 5.84858V4.63492C2.53109 4.05014 2.73548 3.55331 3.14425 3.14442C3.55314 2.73564 4.04864 2.53125 4.63075 2.53125H6.58692V4.04392H4.76625C4.56403 4.04392 4.39309 4.11314 4.25342 4.25158C4.11364 4.38992 4.04375 4.56019 4.04375 4.76242V5.98008C4.04375 6.40764 3.92086 6.79497 3.67509 7.14208C3.42931 7.48919 3.10509 7.73631 2.70242 7.88342V8.11675C3.10509 8.26386 3.42931 8.51097 3.67509 8.85808C3.92086 9.20519 4.04375 9.59253 4.04375 10.0201V11.2338C4.04375 11.4386 4.11364 11.6103 4.25342 11.7486C4.39309 11.887 4.56403 11.9562 4.76625 11.9562H6.58692V13.4689H4.63075Z" />
      </g>
    </svg>
  )
}
