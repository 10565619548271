type DecoProps = {
  variant: 'surface' | 'background';
}

export function Deco({ variant }: DecoProps) {
  return (
    <div className="flex pt-[7px] pb-[5px] flex-col justify-between flex-1 self-stretch">
      <div data-variant={variant} className="data-[variant=surface]:bg-surface data-[variant=background]:bg-background h-0.5 rounded-[1px] self-stretch" />
      <div data-variant={variant} className="data-[variant=surface]:bg-surface data-[variant=background]:bg-background h-0.5 rounded-[1px] self-stretch" />
    </div>
  )
}
