import clsx from "clsx";
import { AriaRole, ReactNode } from "react"

type PopoverProps = {
  children: ReactNode;
  className?: string;
  role?: AriaRole | undefined;
}

export function Popover({ children, className, role = 'menu' }: PopoverProps) {
  return (
    <div role={role} className={clsx('flex p-3 translate-y-1.5 flex-col shadow-modal justify-center items-start gap-4 bg-surface rounded border-2 border-border', className)}>
      {children}
    </div>
  )
}
