import clsx from "clsx";
import { ReactNode } from "react";

type ButtonProps = {
  children?: string;
  leading?: ReactNode;
  variant?: 'accent' | 'surface';
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: VoidFunction;
  size?: 'default' | 'small';
  icon?: ReactNode;
  isFullWidth?: boolean;
}

export function Button({ children, leading, variant = 'accent', isDisabled = false, onClick, size = 'default', isActive, icon, isFullWidth = false }: ButtonProps) {

  return (
    <button
      className={clsx(
        "pb-1 px-0.5 active:pt-[3px] active:pb-0.5 flex items-center justify-center font-youth font-black group transition-all ease-out duration-[160ms]",
        variant === 'accent' ? 'text-font-on-accent' : 'text-accent',
        isDisabled && '!text-font-disabled pointer-events-none',
        size === 'default' ? 'text-base h-[42px]' : 'text-sm',
        isFullWidth && 'w-full',
      )}
      disabled={isDisabled}
      onClick={onClick}
    >
      <div
        data-active={isActive}
        data-variant={variant}
        className={clsx(
        "flex whitespace-nowrap -mx-0.5 -mb-1 group-active:-mb-0.5 group-active:-mt-[3px] items-center justify-center gap-1.5 rounded-lg transition-all ease-out duration-[160ms]",
        'data-[active=true]:data-[variant=surface]:bg-surface-active data-[active=true]:data-[variant=accent]:bg-accent data-[active=true]:border-t-[3px] data-[active=true]:border-b-2',
        size === 'default' ? 'py-1.5 group-active:pb-[5px]' : 'py-1 group-active:pb-[3px]',
        variant === 'accent' ? 'bg-accent' : 'bg-surface group-active:bg-surface-active',
        isDisabled && 'bg-surface-disabled !border-2',
        children && !leading && (size === 'default' ? "px-6" : 'px-4'),
        children && leading && "pl-4 pr-6",
        !children && leading && "px-2.5",
        icon && 'pr-2',
        isFullWidth && 'w-full',
        "group-active:border-t-[3px] group-active:border-b-2 border-border border-2 border-b-4",
      )}>
        {leading}
        {children}
        {icon}
      </div>
    </button>
  )
}