import { AriaRole } from "react";

type CopyProps = {
  variant?: 'default' | 'accent';
  role?: AriaRole | undefined;
  onClick?: VoidFunction;
}

export function Copy({ variant = 'default', role, onClick }: CopyProps) {
  return (
    <svg role={role} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path data-variant={variant} className="data-[variant=default]:fill-font-variant data-[variant=accent]:fill-accent" d="M5.91669 12C5.57293 12 5.27866 11.8776 5.03387 11.6328C4.78908 11.388 4.66669 11.0938 4.66669 10.75V2.75C4.66669 2.40624 4.78908 2.11197 5.03387 1.86718C5.27866 1.62239 5.57293 1.5 5.91669 1.5H11.9167C12.2604 1.5 12.5547 1.62239 12.7995 1.86718C13.0443 2.11197 13.1667 2.40624 13.1667 2.75V10.75C13.1667 11.0938 13.0443 11.388 12.7995 11.6328C12.5547 11.8776 12.2604 12 11.9167 12H5.91669ZM5.91669 10.75H11.9167V2.75H5.91669V10.75ZM3.41669 14.5C3.07293 14.5 2.77866 14.3776 2.53387 14.1328C2.28908 13.888 2.16669 13.5938 2.16669 13.25V4.625C2.16669 4.45278 2.2278 4.30556 2.35002 4.18333C2.47224 4.06111 2.61946 4 2.79169 4C2.96391 4 3.11113 4.06111 3.23335 4.18333C3.35558 4.30556 3.41669 4.45278 3.41669 4.625V13.25H10.0417C10.2139 13.25 10.3611 13.3111 10.4834 13.4333C10.6056 13.5556 10.6667 13.7028 10.6667 13.875C10.6667 14.0472 10.6056 14.1944 10.4834 14.3167C10.3611 14.4389 10.2139 14.5 10.0417 14.5H3.41669Z" />
    </svg>
  )
}
