type PackageIconProps = {
  isActive?: boolean;
}

export function PackageIcon({ isActive }: PackageIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="shrink-0">
      <mask id="mask0_6340_6459" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6340_6459)">
        <path data-active={isActive} className="data-[active=true]:fill-accent fill-font-variant" d="M7.26167 12.8542V8.42302L3.38117 6.17419V10.6054L7.26167 12.8542ZM8.73834 12.8542L12.6188 10.6054V6.17419L8.73834 8.42302V12.8542ZM8 7.14952L11.8305 4.93386L8 2.72236L4.1695 4.93386L8 7.14952ZM2.62284 11.9314C2.38517 11.7932 2.19934 11.6096 2.06534 11.3804C1.93145 11.1511 1.8645 10.8984 1.8645 10.622V5.37769C1.8645 5.10136 1.93145 4.84858 2.06534 4.61935C2.19934 4.39013 2.38517 4.20647 2.62284 4.06836L7.24167 1.41286C7.47934 1.27474 7.73211 1.20569 8 1.20569C8.26789 1.20569 8.52067 1.27474 8.75834 1.41286L13.3772 4.06836C13.6148 4.20647 13.8007 4.39013 13.9347 4.61935C14.0686 4.84858 14.1355 5.10136 14.1355 5.37769V10.622C14.1355 10.8984 14.0686 11.1511 13.9347 11.3804C13.8007 11.6096 13.6148 11.7932 13.3772 11.9314L8.75834 14.5869C8.52067 14.725 8.26789 14.794 8 14.794C7.73211 14.794 7.47934 14.725 7.24167 14.5869L2.62284 11.9314Z" />
      </g>
    </svg>
  )
}
