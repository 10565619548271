import { atom, AtomEffect, DefaultValue, useRecoilValue } from "recoil";
import { HeaderProps } from "../components/Header";

const localStorageEffect = <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue) as T);
    }

    onSet((newValue: T | DefaultValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const expandedFunctionsAtom = atom<number>({
  key: 'expand-functions',
  default: 0,
});

export const expandAllFunctionsTriggerAtom = atom<number>({
  key: 'expand-all-trigger',
  default: 0,
});

export const gasTankAtom = atom<boolean>({
  key: 'gas-tank',
  default: false,
  effects: [
    localStorageEffect<boolean>('gas-tank-status'),
  ],
});

export const tabAtom = atom<HeaderProps['currentTab']>({
  key: 'tab',
  default: 'code',
})
