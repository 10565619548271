import { ReactNode } from "react"
import { useElementSize } from "../utils/utils";

type TabContentBodyProps = {
  children: ReactNode;
}

export function TabContentBody({ children }: TabContentBodyProps) {
  const [tabContentRef, { height: contentHeight }] = useElementSize();

  return (
    <>
      <div ref={tabContentRef} className="flex flex-col relative test items-start gap-2 flex-1 h-full self-stretch overflow-auto hide-scrollbars">
        {children}
      </div>
      <div
        style={{ height: contentHeight }}
        className="absolute bg-screen-effect bottom-0 inset-x-0 w-full pointer-events-none mix-blend-multiply opacity-[0.16] bg-left-top bg-[lightgray] z-10"
      />
      <div
        style={{ height: contentHeight }}
        className="absolute bg-sidebar-light-effect bottom-0 inset-x-0 w-full pointer-events-none z-20 bg-cover bg-no-repeat mix-blend-soft-light"
      />
    </>
  )
}
