type CaretProps = {
  color?: 'default' | 'on-accent' | 'variant' | 'disabled';
  className?: string;
}

export function Caret({ color = 'default', className }: CaretProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path data-color={color} className="data-[color=default]:fill-font data-[color=disabled]:fill-font-disabled data-[color=on-accent]:fill-font-on-accent data-[color=variant]:fill-font-variant" d="M8.00162 10.35C7.89205 10.35 7.78407 10.3269 7.67767 10.2807C7.57127 10.2344 7.47809 10.1713 7.39813 10.0913L4.38807 7.08127C4.22376 6.91697 4.1416 6.72045 4.1416 6.49171C4.1416 6.26296 4.2247 6.0655 4.39088 5.89931C4.55707 5.73312 4.75454 5.65002 4.98329 5.65002C5.21203 5.65002 5.40895 5.73257 5.57404 5.89766L7.99995 8.32357L10.4259 5.89766C10.591 5.73257 10.7879 5.65002 11.0166 5.65002C11.2454 5.65002 11.4428 5.73312 11.609 5.89931C11.7752 6.0655 11.8583 6.26296 11.8583 6.49171C11.8583 6.72045 11.7761 6.91697 11.6118 7.08127L8.60177 10.0913C8.52181 10.1713 8.42919 10.2344 8.3239 10.2807C8.21861 10.3269 8.11119 10.35 8.00162 10.35Z" />
    </svg>
  )
}
