import { ReactNode } from "react"

type TabContentHeaderProps = {
  children: ReactNode;
}

export function TabContentHeader({ children }: TabContentHeaderProps) {
  return (
    <div className="flex flex-col gap-3 items-start self-stretch px-3 pb-3 pt-4 border-b-2 border-border">
      {children}
    </div>
  )
}