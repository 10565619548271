import { chainIds } from "../constants/config";
import { CollapseAllIcon } from "../assets/CollapseAll";
import { Chips } from "../ui-kit/Chips";
import { TabContentBody } from "../ui-kit/TabContentBody";
import { TabContentHeader } from "../ui-kit/TabContentHeader";
import { PositionEntry, Token } from "./PositionEntry";
import { Button } from "../ui-kit/Button";
import { useHash } from "../utils/utils";
import { HexString } from "@mass-money/sdk/web";

const POSITIONS: Record<string, Token[]> = {
  '0xa4b1': [
    { name: 'USDC', quantity: 1000, address: '0x12389F0D4B59eacaB577cBceDE63f0238210B456' },
    { name: 'WETH', quantity: 0.02, address: '0x12389F0D4B59eacaB577cBceDE63f0238210B456' },
    { name: 'WBTC', quantity: 0.0006, address: '0x12389F0D4B59eacaB577cBceDE63f0238210B456' },
  ],
  '0x89': [
    { name: 'USDC', quantity: 100, address: '0x12389F0D4B59eacaB577cBceDE63f0238210B456' },
    { name: 'WETH', quantity: 0.1, address: '0x12389F0D4B59eacaB577cBceDE63f0238210B456' },
  ],
}

export function Positions() {
  const [hash] = useHash();
  const vaultAddress = hash.replace("#", "") as HexString;

  return (
    <>
      <TabContentHeader>
        <div className="flex justify-between w-full items-start self-stretch">
          {/* <Chips label="All networks" />
          <Chips isActive={false} leading={<CollapseAllIcon />} className="!px-2 !py-[5px]" /> */}
          <a data-disabled={!vaultAddress} target="_blank" href={`https://debank.com/profile/${vaultAddress}`} className="w-full data-[disabled=true]:pointer-events-none data-[disabled=true]:cursor-default">
            <Button isDisabled={!vaultAddress} variant="surface" isFullWidth>See my vault on debank</Button>
          </a>
        </div>
      </TabContentHeader>
      <TabContentBody>
        {/* <div className="flex flex-col w-full">
          {Object.keys(chainIds).map(key => 
            <PositionEntry chain={key} tokens={POSITIONS[key]} />
          )}
        </div> */}
        <div />
      </TabContentBody>
    </>
  )
}