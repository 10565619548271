import clsx from "clsx";
import { HTMLInputTypeAttribute, ReactNode, useEffect, useRef, useState } from "react";
import clearIcon from '../assets/Clear.svg';

type InputProps = {
  placeholder?: string;
  value?: string | number;
  leading?: ReactNode;
  trailing?: ReactNode;
  onChange?: (value: string) => void;
  onClear?: VoidFunction;
  isDisabled?: boolean;
  type?: HTMLInputTypeAttribute | undefined;
}

export function Input({ placeholder, value, onChange, onClear, leading, trailing, isDisabled, type = 'text' }: InputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
    }
  }, [isFocused]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsFocused(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="flex p-0.5 rounded-lg relative w-full h-full overflow-hidden"
      onClick={() => setIsFocused(true)}
      tabIndex={-1}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div
        data-focus={isFocused && !isDisabled}
        data-disabled={isDisabled}
        className={clsx(
          'gap-2 -mx-0.5 flex items-center flex-1 relative',
          'py-2.5 px-3 group',
          'w-full h-full',
          'rounded-lg border-b-2 border-x-2 border-t-4 !border-border ',
          'bg-[#333] bg-input-gradient ',
          'data-[focus=true]:bg-input-active-gradient ',
          'data-[focus=true]:bg-surface-transparent-active',
          'data-[disabled=true]:bg-input-disabled-gradient data-[disabled=true]:bg-[#222]',
          trailing && '!py-1.5 !pr-1.5',
        )}
      >
        {leading}
        <input
          disabled={isDisabled}
          type={type}
          ref={inputRef}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          placeholder={placeholder}
          className={clsx(
            "!ring-0 border-none p-0 bg-transparent w-full appearance-none",
            "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
            "placeholder:text-font-variant disabled:placeholder:text-font-disabled text-base font-medium text-font",
            // TODO: custom caret
            "caret-accent",
          )}
        />
        {trailing}
        {onClear && value && <img role="button" onClick={onClear} src={clearIcon} />}
        <div className="absolute bg-screen-effect inset-0 w-full h-full pointer-events-none mix-blend-multiply opacity-[0.16] bg-left-top bg-[lightgray] z-10" />
        <div className="absolute bg-input-light-effect inset-0 w-full h-full pointer-events-none z-20 bg-cover bg-no-repeat mix-blend-soft-light" />
      </div>
    </div>
  )
}
