import { computeExpectedVaultAddress } from "@mass-money/sdk/web";
import { Button } from "../ui-kit/Button";
import {
  useHash,
  useNet,
  useWalletClient,
} from "../utils/utils";
import { keccak256 } from "viem";

export function Connect({ isSmall }: { isSmall?: boolean }) {
  const net = useNet();
  const walletClient = useWalletClient();
  const [, setHash] = useHash();

  const handleConnect = async () => {
    if (!net || !walletClient) {
      return;
    }
    const accounts = await walletClient.requestAddresses();
    if (!accounts.length) {
      return;
    }
    try {
      const walletAddress = accounts[0];
      const vaultAddress = await computeExpectedVaultAddress({
        rpcUrl: net.rpc,
        creatorAddress: walletAddress,
        salt: keccak256(walletAddress),
      });
      setHash(vaultAddress);
    } catch (error) {
      console.error("Failed to connect", error);
    }
  };

  return (
    <Button size={isSmall ? "small" : "default"} onClick={handleConnect}>
      Connect
    </Button>
  );
}
