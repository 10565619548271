type CollapseAllIconProps = {
  variant?: 'default' | 'disabled';
}

export function CollapseAllIcon({ variant = 'default' }: CollapseAllIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_6164_25379" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6164_25379)">
        <path data-variant={variant} className="data-[variant=default]:fill-font data-[variant=disabled]:fill-font-disabled" d="M9.0002 13.1934L6.14701 16.0468C5.98851 16.2051 5.78957 16.2843 5.5502 16.2843C5.31082 16.2843 5.11188 16.2051 4.95338 16.0468C4.79501 15.8883 4.71582 15.6893 4.71582 15.45C4.71582 15.2106 4.79501 15.0116 4.95338 14.8531L7.78801 12.0187C8.11738 11.6893 8.52145 11.5246 9.0002 11.5246C9.47895 11.5246 9.88301 11.6893 10.2124 12.0187L13.047 14.8531C13.2054 15.0116 13.2846 15.2106 13.2846 15.45C13.2846 15.6893 13.2054 15.8883 13.047 16.0468C12.8885 16.2051 12.6896 16.2843 12.4502 16.2843C12.2108 16.2843 12.0119 16.2051 11.8534 16.0468L9.0002 13.1934ZM9.0002 4.80651L11.8534 1.95314C12.0119 1.79476 12.2108 1.71558 12.4502 1.71558C12.6896 1.71558 12.8885 1.79476 13.047 1.95314C13.2054 2.11164 13.2846 2.31058 13.2846 2.54995C13.2846 2.78933 13.2054 2.98826 13.047 3.14676L10.2124 5.9812C9.88301 6.31058 9.47895 6.47526 9.0002 6.47526C8.52145 6.47526 8.11738 6.31058 7.78801 5.9812L4.95338 3.14676C4.79501 2.98826 4.71582 2.78933 4.71582 2.54995C4.71582 2.31058 4.79501 2.11164 4.95338 1.95314C5.11188 1.79476 5.31082 1.71558 5.5502 1.71558C5.78957 1.71558 5.98851 1.79476 6.14701 1.95314L9.0002 4.80651Z" />
      </g>
    </svg>
  )
}
