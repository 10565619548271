import { useRef, useEffect, ReactNode } from "react";
import { Cross } from "../assets/Cross";

type ModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  children: ReactNode;
  title: string;
}

export function Modal({ children, isOpen, onClose, title }: ModalProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      if (dialogRef.current) dialogRef.current.showModal();

    } else {
      if (dialogRef.current) dialogRef.current.close();

    }
  }, [isOpen]);

  const handleClick = (event: React.MouseEvent<HTMLDialogElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  }

  return (
    <dialog ref={dialogRef} className="backdrop:bg-overlay shadow-modal p-3 text-font border-2 bg-surface border-border rounded-lg w-[480px] flex-col justify-center items-start gap-3 flex-shrink-0" onClick={handleClick}>
      <div className="flex flex-col">
        <div className="flex items-center gap-2 self-stretch font-medium">
          <span className="flex-1">{title}</span>
          <Cross role="button" onClick={onClose} />
        </div>
        {children}
      </div>
    </dialog>
  );
}
