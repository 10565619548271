import clsx from "clsx";
import { ReactNode } from "react";

type ChipsProps = {
  label?: string;
  leading?: ReactNode;
  icon?: ReactNode;
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: VoidFunction;
  className?: string;
}

export function Chips({ icon, isDisabled, label, leading, onClick, isActive, className }: ChipsProps) {
  return (
    <button
      className={clsx(
        "text-font h-[34px] pb-1 px-0.5 active:pt-[3px] active:pb-0.5 flex items-center justify-center font-youth text-sm font-medium group transition-all ease-out duration-[160ms]",
        isDisabled && '!text-font-disabled pointer-events-none',
      )}
      disabled={isDisabled}
      onClick={onClick}
    >
      <div
        data-active={isActive}
        className={clsx(
          "flex -mx-0.5 -mb-1 group-active:-mb-0.5 group-active:-mt-[3px] items-center justify-center gap-2 rounded-lg transition-all ease-out duration-[160ms]",
          "py-1 group-active:pb-[3px] bg-surface group-active:bg-surface-active min-w-12",
          'data-[active=true]:bg-surface-active data-[active=true]:border-t-[3px] data-[active=true]:border-b-2',
          isDisabled && 'bg-surface-disabled !border-2',
          label && !leading && !icon && "px-4",
          label && leading && !icon && "pl-0.5 pr-4 !py-0.5",
          label && leading && icon && "pl-0.5 pr-2 !py-0.5",
          label && !leading && icon && "pl-4 pr-2",
          !label && !icon && leading && "px-2.5",
          "group-active:border-t-[3px] group-active:border-b-2 border-border border-2 border-b-4",
          className,
        )}>
        {leading}
        {label}
        {icon}
      </div>
    </button>
  )
}
