import { massApiUrl, shortenAddress, useClickOutside, useHash, useMyAddress, useNet, useWalletClient, waitForConfirmedTx, waitForSchedulerTask } from "../utils/utils";
import { Chips } from "../ui-kit/Chips";
import { useRef, useState } from "react";
import { Popover } from "../ui-kit/Popover";
import { computeExpectedVaultAddress, createVault, createVaultAndExecute, DataToSign, fetchVaultOwner, HexString } from "@mass-money/sdk/web";
import clsx from "clsx";
import { keccak256, WalletClient } from "viem";
import { toast } from "react-toastify";
import { Caret } from "../assets/Caret";
import { useRecoilValue } from "recoil";
import { gasTankAtom } from "../atoms/atom";
import { Check } from "../assets/Check";
import { Copy } from "../assets/Copy";

export function WalletMenu() {
  const myAddress = useMyAddress();
  const walletClient = useWalletClient();
  const net = useNet();
  const isGasTankEnabled = useRecoilValue(gasTankAtom);

  const [isOpen, setIsOpen] = useState(false);
  const [hash, setHash] = useHash();
  const vaultAddress = hash.replace("#", "") as HexString;
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const signTypedData = async (
    walletClient: WalletClient,
    account: HexString,
    dataToSign: DataToSign
  ) => {
    const signature = await toast.promise(
      walletClient.signTypedData({
        account,
        types: dataToSign.types,
        domain: dataToSign.domain,
        message: dataToSign.message,
        primaryType: dataToSign.primaryType,
      }),
      {
        pending: "Signing message...",
        error: "User rejected the request",
      }
    );

    return signature;
  };

  const gotoAddress = (address: string) => {
    setHash(address);
  };

  const handleCreateNewVaultClick = async () => {
    if (!net || !myAddress || !walletClient) {
      return;
    }

    const mainVaultSalt = keccak256(myAddress);
    const mainVaultAddress = await computeExpectedVaultAddress({
      creatorAddress: myAddress,
      rpcUrl: net.rpc,
      salt: mainVaultSalt,
    });

    let salt: HexString | undefined = undefined;
    try {
      await fetchVaultOwner(net.rpc, mainVaultAddress);
    } catch (e) {
      // main vault does not exist
      salt = mainVaultSalt;
    }

    if (!isGasTankEnabled) {
      const { tx, vaultAddress } = await createVault({
        creatorAddress: myAddress,
        rpcUrl: net.rpc,
        salt,
      });

      if (tx) {
        const txId = await toast.promise(
          walletClient.sendTransaction({
            account: myAddress,
            chain: net.chain,
            to: tx.to,
            value: tx.value,
            data: tx.data,
          }),
          {
            pending: "Signing transaction...",
            error: "User rejected the request",
          }
        );

        await toast.promise(waitForConfirmedTx(txId, net), {
          pending: "Sending transaction...",
          success: {
            render() {
              return (
                <>
                  <div>Vault created 🚀</div>
                  <a href={`${net.explorer}/tx/${txId}`} target="_blank">
                    view on explorer
                  </a>
                </>
              );
            },
          },
          error:
            "Transaction is not yet confirmed, please refresh the page later",
        });

        gotoAddress(vaultAddress);
      }
    } else {
      const { dataToSign, buildTx, vaultAddress } = await createVaultAndExecute(
        {
          creatorAddress: myAddress,
          rpcUrl: net.rpc,
          salt,
        },
        "gasTanker.activate();"
      );

      const signature = await signTypedData(
        walletClient,
        myAddress,
        dataToSign
      );

      const tx = buildTx(signature);

      const taskId = await toast.promise(
        async () => {
          const res = await fetch(
            `${massApiUrl}/compiler/create-vault-and-execute`,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify({
                ...tx,
                value: "0x" + tx.value.toString(16),
                chainId: net.chain.id,
              }),
            }
          );
          if (!res.ok) {
            throw new Error("Failed to create vault");
          }
          const { taskId } = await res.json();
          return taskId;
        },
        {
          pending: "Signing transaction...",
          error: "Failed to create vault 💥",
        }
      );

      await toast.promise(waitForSchedulerTask(taskId, true), {
        pending: "Sending transaction...",
        success: {
          render({ data }) {
            return (
              <>
                <div>Vault created 🚀</div>
                <a href={`${net.explorer}/tx/${data}`} target="_blank">
                  view on explorer
                </a>
              </>
            );
          },
        },
        error:
          "Transaction is not yet confirmed, please refresh the page later",
      });

      gotoAddress(vaultAddress);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <Chips 
        isActive={isOpen} 
        onClick={() => setIsOpen(true)} 
        label={shortenAddress(myAddress)}
        icon={<Caret className={clsx('transition-all ease-out duration-[160ms] w-4 h-4', isOpen ? "rotate-180" : "")} />}
      />
      {isOpen &&
        <Popover className="absolute">
          <div className="flex flex-col items-start self-stretch gap-3 w-full">
            <div className="font-youth font-medium flex-1">Your addresses</div>
            <div className="flex flex-col gap-2 w-full">
              <div className="text-sm font-medium text-font-variant">Wallet address</div>
              <div className="flex gap-4 flex-1 text-sm font-medium font-youth w-full justify-between">
                <span className="max-w-[30ch] break-words whitespace-normal">{myAddress}</span>
                {myAddress &&
                  <div
                    role="button"
                    onClick={() => navigator.clipboard.writeText(myAddress)}
                    className="h-4 w-4"
                  >
                    <Copy variant="accent" />
                  </div>}
              </div>
            </div>
          </div>
          <div className="flex gap-3 items-start self-stretch h-full">
            <div className="w-0.5 rounded-[1px] bg-background self-stretch" />
            <div className="flex flex-col gap-6 items-start">
              <div className="flex flex-col gap-2 justify-center items-start self-stretch">
                <span className="text-sm text-font-variant">Active vault address</span>
                <div className="flex gap-4">
                  <span className="max-w-[25ch] break-words whitespace-normal">{vaultAddress}</span>
                  <div
                    role="button"
                    onClick={() => navigator.clipboard.writeText(vaultAddress)}
                    className="h-4 w-4"
                  >
                    <Copy variant="accent" />
                  </div>
                  <div className="h-4 w-4">
                    <Check />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3 items-start self-stretch text-accent font-medium">
                <span role="button" onClick={handleCreateNewVaultClick}>Create another vault</span>
                <span role="button">Add external vault</span>
              </div>
            </div>
          </div>
        </Popover>}
    </div>
  )
}
