import clsx from "clsx";
import { Dot } from "../ui-kit/Dot";
import { useMemo } from "react";

type NavProps = {
  label: string;
  isActive: boolean;
  onClick?: VoidFunction;
}

export function Nav({ label, isActive, onClick }: NavProps) {
  const dotVariant = useMemo(() => 
    isActive ? 'accent' : 'neutral', 
    [isActive]
  );

  return (
    <div
      data-active={isActive}
      onClick={onClick}
      className={clsx(
        "flex gap-2 text-base font-medium font-youth items-center cursor-pointer",
        "data-[active=true]:text-font data-[active=false]:text-font-variant"
      )}
    >
      {label}
      <Dot variant={dotVariant} />
    </div>
  )
}