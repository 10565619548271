import {
  Chain,
  arbitrum,
  avalanche,
  base,
  bsc,
  mainnet,
  optimism,
  polygon,
} from "viem/chains";
import arbitrumLogo from "../assets/Arbitrum.svg";
import baseLogo from "../assets/Base.svg";
import ethereumLogo from "../assets/Ethereum.svg";
import polygonLogo from "../assets/Polygon.svg";
import optimismLogo from "../assets/Optimism.svg";
import avalancheLogo from "../assets/Avalanche.svg";
import bnbChainLogo from "../assets/BnbChain.svg";
import { HexString } from "@mass-money/sdk/web";

export const chainIds: Record<string, Net> = {
  "0x89": {
    name: "Polygon",
    rpc: `https://polygon-mainnet.g.alchemy.com/v2/SFTjd__zMX-1yKngYZdyvEZgyRWIII2g`,
    explorer: "https://polygonscan.com",
    icon: polygonLogo,
    apiId: "poly",
    chain: polygon,
    usdc: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    apiKey: process.env.MASS_POLYGON_API_KEY,
    apiUrl: 'https://api.polygonscan.com/api',
  },
  //   '0x1': {
  //       name: 'Ethereum',
  //       rpc: `https://eth-mainnet.g.alchemy.com/v2/tk-O9JAUX7-anRBtT1unevAhT06DwUaw`,
  //       explorer: 'https://etherscan.io',
  //       icon: ethereumLogo,
  //       apiId: 'eth',
  //       chain: mainnet,
  //       usdc: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  //   },
  "0xa4b1": {
    name: "Arbitrum",
    rpc: `https://arb-mainnet.g.alchemy.com/v2/tk-O9JAUX7-anRBtT1unevAhT06DwUaw`,
    explorer: "https://arbiscan.io",
    icon: arbitrumLogo,
    apiId: "arbi",
    chain: arbitrum,
    usdc: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
    apiKey: process.env.MASS_ARBITRUM_API_KEY,
    apiUrl: 'https://api.arbiscan.io/api',
  },
  "0xa86a": {
    name: "Avalanche",
    rpc: `https://api.avax.network/ext/bc/C/rpc`,
    explorer: "https://snowtrace.io",
    icon: avalancheLogo,
    apiId: "avax",
    chain: avalanche,
    usdc: "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
  },
  "0xa": {
    name: "Optimism",
    rpc: `https://opt-mainnet.g.alchemy.com/v2/tk-O9JAUX7-anRBtT1unevAhT06DwUaw`,
    explorer: "https://optimistic.etherscan.io",
    apiUrl: "https://api-optimistic.etherscan.io/api",
    icon: optimismLogo,
    apiId: "opti",
    chain: optimism,
    usdc: "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
    apiKey: process.env.MASS_OPTIMISM_API_KEY,
  },
  "0x38": {
    name: "BNB Chain",
    rpc: "https://bsc-dataseed.binance.org/",
    explorer: "https://bscscan.com",
    icon: bnbChainLogo,
    apiId: "bsc",
    chain: bsc,
    usdc: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
  },
  "0x2105": {
    name: "Base",
    rpc: "https://base-mainnet.g.alchemy.com/v2/tk-O9JAUX7-anRBtT1unevAhT06DwUaw",
    explorer: "https://basescan.org",
    apiId: "base",
    icon: baseLogo,
    chain: base,
    usdc: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
    apiKey: process.env.MASS_BASE_API_KEY,
    apiUrl: 'https://api.basescan.org/api',
  },
};

export interface Net {
  name: string;
  rpc: string;
  explorer: string;
  icon: string;
  apiId: string;
  chain: Chain;
  usdc: HexString;
  apiKey?: string;
  apiUrl?: string;
}
