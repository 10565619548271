import clsx from 'clsx';
import Editor, { monaco } from 'react-monaco-editor';

type CodeDisplayProps = {
  code: string;
  className?: string;
}

export function CodeDisplay({ code, className }: CodeDisplayProps) {

  const editorDidMount = (editor: monaco.editor.IStandaloneCodeEditor, m: typeof monaco) => {
    const defineCustomTheme = (m: typeof monaco) => {
      m.editor.defineTheme('custom-dark', {
        base: 'vs-dark',
        inherit: true,
        rules: [
          { token: "keyword", foreground: "60AFF0" },
          { token: "comment", foreground: "6EB264" },
          { token: "string", foreground: "FF8F61" },
          { token: "number", foreground: "CAF7B2" },
        ],
        colors: {},
      });
      m.editor.setTheme('custom-dark');
    };

    editor.focus();
    defineCustomTheme(m);
  };

  return (
    <div className={clsx("flex flex-col h-full w-full min-h-32 rounded gap-1.5 border-2 border-[#272727] bg-[#333] bg-function-gradient self-stretch items-start", className)}>
      <Editor
        language="sol"
        theme="custom-dark"
        value={code}
        editorDidMount={editorDidMount}
        options={{
          lineNumbers: 'off',
          readOnly: true,
          fontSize: 14,
          lineHeight: 20,
          fontWeight: '500',
          fontFamily: 'Outfit',
          renderLineHighlight: 'none',
          selectionHighlight: false,
          glyphMargin: false,
          scrollbar: {
            horizontal: 'hidden',
            vertical: 'hidden',
          },
          hideCursorInOverviewRuler: true,
          overviewRulerBorder: false,
          overviewRulerLanes: 0,
          minimap: {
            enabled: false,
          },
        }}
      />
    </div>
  )
}
