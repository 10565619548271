import { Fragment, useEffect, useMemo, useState } from "react";
import { CompiledVaultOperation, HexString, Tx } from "@mass-money/sdk/web";
import { IExecutor, newSession } from "evm-js-emulator";
import { Net } from "../constants/config";
import { BigNumber, utils } from "ethers";
import {
  KNOWN_CONTRACT,
  ERC20,
} from "evm-js-emulator/tests/known-contracts.js";
import {
  toUint,
  parseBuffer,
  MAX_UINT,
  to0xAddress,
} from "evm-js-emulator/src/utils";
import { execWatchInstructions } from "evm-js-emulator/tests/test-utils";
import dedent from "dedent";
import { by, isAllowed, massApiUrl, notNil, toNumber } from "../utils/utils";
import { Feedback, FeedbackProps } from "../ui-kit/Feedback";
export interface Simulation {
  from: HexString;
  to: HexString;
  sim: CompiledVaultOperation;
  value: HexString;
}

export interface ERC20Transfer {
  type: "erc20Transfer";
  from: HexString;
  to: HexString;
  token: HexString;
  amt: BigNumber;
  reverted?: boolean;
}

export interface EmittedLog {
  type: "log";
  message: string;
  value?: BigNumber;
  token?: HexString;
  reverted?: boolean;
}

type GeneralEvt = ERC20Transfer | EmittedLog;
type VaultEvt = VaultTransfer | EmittedLog;

export interface Evts {
  allEvents: GeneralEvt[];
  vaultEvents: VaultEvt[];
  tokensByAddress: Map<string, Token>;
}

export type SimulationResult =
  | ({
      type: "ok";
    } & Evts)
  | ({
      type: "error";
      error: string;
    } & Evts)
  | { type: "loading" };

export interface VaultTransfer {
  type: "erc20Transfer";
  from: string;
  to: string;
  qty: TokenQty;
  reverted?: boolean;
}

interface TokenQty {
  qty: number;
  usd: number;
  token: Token;
}

const vaultAbi = new utils.Interface([
  "function callHyvm(bytes memory calldata)",
]);

const vaultFactoryAbi = new utils.Interface(["function nestedVaults(address)"]);

const uniswapAbi = new utils.Interface([
  "function swapExactTokensForTokens(uint,uint,address[],address,uint)",
]);

const sdkEventsAbi = new utils.Interface([
  "event LogMessage(string message)",
  "event LogValue(string message, uint256 value)",
  "event LogToken(string message, uint256 value, address token)",
]);

const WBNB = new utils.Interface([
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{ name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "guy", type: "address" },
      { name: "wad", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "src", type: "address" },
      { name: "dst", type: "address" },
      { name: "wad", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "wad", type: "uint256" }],
    name: "withdraw",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "dst", type: "address" },
      { name: "wad", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "deposit",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { name: "", type: "address" },
      { name: "", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  { payable: true, stateMutability: "payable", type: "fallback" },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "src", type: "address" },
      { indexed: true, name: "guy", type: "address" },
      { indexed: false, name: "wad", type: "uint256" },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "src", type: "address" },
      { indexed: true, name: "dst", type: "address" },
      { indexed: false, name: "wad", type: "uint256" },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "dst", type: "address" },
      { indexed: false, name: "wad", type: "uint256" },
    ],
    name: "Deposit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "src", type: "address" },
      { indexed: false, name: "wad", type: "uint256" },
    ],
    name: "Withdrawal",
    type: "event",
  },
]);
const opts = {
  contractsNames: {
    ...Object.fromEntries(KNOWN_CONTRACT.map((c) => [c.address, c.name])),
    "0xdef1c0ded9bec7f1a1670819833240f027b25eff": "ZeroEx",
    "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D": "Uniswap",
    "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57": "Paraswap",
    "0x55d398326f99059fF775485246999027B3197955": { name: "USDT", abi: ERC20 },
    "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d": { name: "USDC", abi: ERC20 },
    "0x2791bca1f2de4661ed88a30c99a7a9449aa84174": { name: "USDC", abi: ERC20 },
    "0x36dac1c6a72f94c13369db9dadcbd79ba5425019": "HyVM",
    "0x4242442424424244242442424424244242442424": "me",
    "0x26f2627005738e5827ab0a8da5bcd6fac72f6e20": "vault_proxy_resolver",
    "0x65b255e131538028b86b46d134dd0786b677f537": {
      name: "vault1_proxy",
      abi: vaultAbi,
    },
    "0xfcbd8be78ae3b0ab23c9c870c0af4d5497461136": {
      name: "vault1",
      abi: vaultAbi,
    },
    "0x7922629c1d06436db49106fc0676180ab2596b2d": {
      name: "old_vault_factory",
      abi: vaultFactoryAbi,
    },
    "0xE87a86F56c837258aAee1f1312fa7042979bB086": {
      name: "vault_factory",
      abi: vaultFactoryAbi,
    },
    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c": {
      name: "WBNB",
      abi: WBNB,
    },
    "0x10ed43c718714eb63d5aa57b78b54704e256024e": {
      name: "uniswap",
      abi: uniswapAbi,
    },
  },
};

export function Simulator({ tx, net }: { tx: Simulation; net: Net }) {
  const [simResult, setSimResult] = useState<SimulationResult>({
    type: "loading",
  });

  const setResult = (result: SimulationResult) => {
    setSimResult(result);
  };

  const feedback = useMemo(() => {
    switch (simResult.type) {
      case "error":
        return {
          label: "Simulating failed",
          status: "error",
          description: simResult.error,
        };
      case "ok":
        return {
          label: "Simulating successful",
          status: "success",
          description: simResult.vaultEvents.map((r, i) => (
            <Fragment key={i}>
              {r.type === "erc20Transfer" ? (
                <EvtTransfer r={r} />
              ) : (
                <EvtLog log={r} tokens={simResult.tokensByAddress} />
              )}
            </Fragment>
          )),
        };
      case "loading":
      default:
        return {
          label: "Simulating...",
          status: "accent",
        };
    }
  }, [simResult.type]);

  useEffect(() => {
    setSimResult({ type: "loading" });

    (async () => {
      const allEvents: GeneralEvt[] = [];

      try {
        const session = newSession({
          ...opts,
          rpcUrl: net.rpc,
        });

        // execute transactions
        const execute = async ({ data, to, value }: Tx) => {
          const exec = await session.prepareCall({
            origin: toUint(tx.from),
            callvalue: toUint(value.toString(16)),
            calldata: parseBuffer(data),
            contract: toUint(to),
            gasLimit: MAX_UINT,
            gasPrice: toUint(0xffff),
            retdatasize: 0,
            static: false,
            timestamp: Math.floor(Date.now() / 1000),
          });
          // watch all logs emitted that might interest us, and accumulate them in "allEvents"
          watch(allEvents, exec);

          // this will execute the tx, and log execution in console.
          (await execWatchInstructions(exec, 5)) ?? [];
        };

        // simulate approvals
        for (const a of tx.sim.requiredApproves) {
          if (
            !(await isAllowed(
              a.token,
              tx.from,
              tx.sim.vaultAddress,
              a.knownAmount?.amount
            ))
          ) {
            await execute(a.performApproveMax);
          }
        }

        // simulate tx
        if (tx.sim.tx) {
          await execute(tx.sim.tx);
        }

        setResult({
          type: "ok",
          ...(await parseExecEvents(allEvents, tx, net)),
        });
      } catch (e) {
        console.error("Failed simulation", e);
        try {
          setResult({
            type: "error",
            error: (e as any).message,
            ...(await parseExecEvents(allEvents, tx, net)),
          });
        } catch (e2) {
          console.error("Failed to infer logs of errored simulation", e2);
          setResult({
            type: "error",
            error: (e as any).message,
            allEvents: [],
            vaultEvents: [],
            tokensByAddress: new Map(),
          });
        }
      }
    })();
  }, [tx]);

  return (
    <Feedback
      status={feedback.status as FeedbackProps["status"]}
      title={feedback.label}
      description={feedback.description}
    />
  );
}

const transferTopic = ERC20.getEventTopic(
  ERC20.getEvent("Transfer")
).toLowerCase();
const logMsgTopic = sdkEventsAbi.getEventTopic("LogMessage").toLowerCase();
const logValTopic = sdkEventsAbi.getEventTopic("LogValue").toLowerCase();
const logTokenTopic = sdkEventsAbi.getEventTopic("LogToken").toLowerCase();
const watchTopics = [transferTopic, logMsgTopic, logValTopic, logTokenTopic];

// collect logs (even on failed sub-txs)
function watch(logs: GeneralEvt[], exec: IExecutor) {
  let subLogs: GeneralEvt[];
  exec.onStartingCall((newExec) => watch((subLogs = []), newExec));
  exec.onEndingCall((_, __, success) => {
    if (!success) {
      subLogs.forEach((l) => (l.reverted = true));
    }
    logs.push(...subLogs);
  });
  exec.onLog((_log) => {
    const log = {
      address: to0xAddress(_log.address),
      data: "0x" + Buffer.from(_log.data).toString("hex"),
      topics: _log.topics.map(
        (x) => "0x" + x.toString(16).padStart(64, "0").toLowerCase()
      ),
    } as const;
    // filter logs
    if (!log.data.length || !log.topics.some((x) => watchTopics.includes(x))) {
      return;
    }
    // ERC20 transfer
    if (log.topics.includes(transferTopic)) {
      const parsed = ERC20.parseLog(log);
      logs.push({
        type: "erc20Transfer",
        token: log.address,
        from: parsed.args.from.toLowerCase(),
        to: parsed.args.to.toLowerCase(),
        amt: parsed.args.value,
      });
      return;
    }
    // call to log() function
    let topic;
    if (log.topics.includes(logMsgTopic)) {
      topic = "LogMessage";
    } else if (log.topics.includes(logValTopic)) {
      topic = "LogValue";
    } else if (log.topics.includes(logTokenTopic)) {
      topic = "LogToken";
    } else {
      return;
    }
    const emitted = sdkEventsAbi.decodeEventLog(topic, log.data);
    logs.push({
      type: "log",
      message: emitted.message,
      value: emitted.value,
      token: emitted.token?.toLowerCase(),
    });
  });
}

type Token = {
  id: string;
  symbol: string;
  logo: string;
  decimals: number;
  quote: number;
};

// ===== infer TX events
async function parseExecEvents(
  allEvents: GeneralEvt[],
  tx: Simulation,
  net: Net
) {
  const vaultAddress = tx.to.toLowerCase();
  const owner = tx.from.toLowerCase();
  const addresses = [vaultAddress, owner];
  const innerTransfers = notNil(
    allEvents.map((x) => {
      if (x.type !== "erc20Transfer") {
        return x;
      }
      if (!addresses.includes(x.from) && !addresses.includes(x.to)) {
        return null;
      }
      return x;
    })
  );

  let tokensByAddress = new Map<string, Token>();

  // fake virtual usd token used by the SDK
  const virtualUsdAddr = "0xaaaaaaaaaaaaaaaaaaaaffffffffffffffffffff";
  tokensByAddress.set(virtualUsdAddr, {
    id: virtualUsdAddr,
    symbol: "USD",
    logo: "https://images.vexels.com/content/157443/preview/marketing-dollar-sign-icon-944793.png",
    decimals: 18,
    quote: 1,
  });

  const innerTransfersWithoutVirtualUsd = innerTransfers.filter(
    (i) => i.token !== virtualUsdAddr
  );

  if (innerTransfersWithoutVirtualUsd.length) {
    const result = await fetch(`${massApiUrl}/graphql`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        query: dedent`query FetchSimulatedTransferedTokens($ids: [ChainAddress!]!) {
                            tokens(ids: $ids, noUnwrap: true) {
                              id
                              symbol
                              logo
                              decimals
                              quote
                            }
                          }
                          `,
        variables: {
          ids: [
            ...new Set(
              notNil(
                innerTransfersWithoutVirtualUsd.map((x) => {
                  if (x.type === "erc20Transfer") {
                    return `${net.apiId}:${x.token.toLowerCase()}`;
                  }
                  if (x.type === "log" && x.token) {
                    return `${net.apiId}:${x.token.toLowerCase()}`;
                  }
                  return null;
                })
              )
            ),
          ],
        },
      }),
    }).then((x) => x.json());

    tokensByAddress = by(result.data.tokens, (x) => x.id.split(":")[1]);
  }

  const addrName = (addr: string) =>
    addr === vaultAddress ? "vault" : addr === owner ? "owner" : addr;

  const vaultEvents = innerTransfers.map<VaultEvt>((t) => {
    if (t.type !== "erc20Transfer") {
      return t;
    }
    const info = tokensByAddress.get(t.token)!;
    return {
      type: "erc20Transfer",
      token: t.token,
      qty: buildQty(t.amt, info),

      from: addrName(t.from),
      to: addrName(t.to),
      logo: info.logo,
      name: info.symbol,
      reverted: t.reverted,
    };
  });
  return { allEvents, vaultEvents, tokensByAddress };
}

function buildQty(amt: BigNumber, info: Token): TokenQty {
  const qty = toNumber(amt, info.decimals);
  return {
    qty,
    usd: (info.quote ?? 0) * qty,
    token: info,
  };
}

function EvtTransfer({ r }: { r: VaultTransfer }) {
  return (
    <div className={r.reverted ? "line-through opacity-50" : ""}>
      Transfered
      <InlineTokenQty qty={r.qty} />
      &nbsp; from &nbsp;
      <b className="text-info">{r.from}</b>
      &nbsp; to &nbsp;
      <b className="text-info">{r.to}</b>
    </div>
  );
}

function InlineTokenQty({ qty }: { qty: TokenQty }) {
  return (
    <>
      &nbsp;
      <span>
        {qty.qty.toFixed(5)}
        &nbsp;
        {qty.token.symbol}
        {/* <img src={qty.token.logo} className="w-5 mx-2 inline" /> */}
      </span>
      {/* &nbsp;(≈ ${qty.usd.toFixed(4)}) &nbsp; */}
    </>
  );
}

function EvtLog({
  log,
  tokens,
}: {
  log: EmittedLog;
  tokens: Map<string, Token>;
}) {
  let details = <></>;
  if (log.value) {
    const tok = tokens.get(log.token ?? "");
    if (tok) {
      details = (
        <span>
          <InlineTokenQty qty={buildQty(log.value, tok)} />{" "}
          {/* <i>
            (hex: {log.value.toHexString()}, dec: {log.value.toString()})
          </i> */}
        </span>
      );
    } else {
      details = (
        <span>
          &nbsp; {log.value.toHexString()}
          {/* <i>(dec: {log.value.toString()})</i> */}
        </span>
      );
    }
  }

  return (
    <div>
      {log.message}
      {details}
    </div>
  );
}
