import { useState } from "react"
import { Trash } from "../assets/Trash"
import { Check } from "../assets/Check";
import { Cross } from "../assets/Cross";

type DeleteOrderProps = {
  onDelete?: VoidFunction;
}

export function DeleteOrder({ onDelete }: DeleteOrderProps) {
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);

  return (
    isConfirmationPending
      ? (
        <div className="flex px-1 h-6 rounded gap-2 self-stretch bg-[#FA25651F] items-center">
          <span className="text-sm font-medium text-danger">Delete ?</span>
          <div className="hover:bg-[#FA25651F] rounded-sm p-[3px]" role="button" onClick={onDelete} >
            <Check variant="danger" className="w-[18px] h-[18px]" />
          </div>
          <div className="hover:bg-[#FA25651F] rounded-sm p-[3px]" role="button" onClick={() => setIsConfirmationPending(false)}>
            <Cross variant="danger" className="w-[18px] h-[18px]" />
          </div>
        </div>
      )
      : (
        <div onClick={() => setIsConfirmationPending(true)} role="button" className="flex items-center gap- p-0.5 rounded hover:bg-[#FA25651F] group">
          <Trash />
        </div>
      )
  )
}