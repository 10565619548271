import clsx from "clsx";
import { ReactNode } from "react"

type MainContentProps = {
  children: ReactNode;
  className?: string;
}

export function MainContent({ children, className }: MainContentProps) {
  return (
    <div className={clsx("flex flex-col flex-1 self-stretch items-start", className)}>
      {children}
    </div>
  )
}
