import { useState } from "react"
import { TabBar } from "../ui-kit/TabBar";
import { Functions } from "./Functions";
import { Orders } from "./Orders";
import { Positions } from "./Positions";

export function SideContent() {
  const [currentTab, setCurrentTab] = useState<'functions' | 'orders' | 'positions'>('functions');

  return (
    <div className="flex flex-col w-[360px] items-start self-stretch gap-1 px-2 pb-2 rounded-t-2xl border-x-2 border-t-2 border-border bg-surface bg-surface-gradient h-full">
      <TabBar tabs={['functions', 'orders', 'positions']} activeTab={currentTab} onChange={(value) => setCurrentTab(value as 'functions' | 'orders' | 'positions')} />
      <div className="flex relative flex-col items-start flex-1 self-stretch rounded-lg border-t-4 border-b-2 border-x-2 border-border bg-[rgba(0,_0,_0,_0.10)] h-full overflow-hidden">
        {currentTab === 'functions' && <Functions />}
        {currentTab === 'orders' && <Orders />}
        {currentTab === 'positions' && <Positions />}
      </div>
    </div>
  )
}
