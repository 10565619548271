import clsx from "clsx";
import { ReactNode } from "react"

type MainScreenProps = {
  children: ReactNode;
  className?: string;
}

export function MainScreen({ children, className }: MainScreenProps) {
  return (
    <div className={clsx("flex relative shadow-editor h-full w-full p-3 rounded-t-xl border-x-2 border-t-4 border-border items-start gap-1 flex-1 self-stretch bg-editor-gradient bg-background", className)}>
      {children}
      <div className="absolute bg-screen-effect inset-0 w-full h-full pointer-events-none mix-blend-multiply opacity-[0.16] bg-left-top bg-[lightgray] z-10" />
      <div className="absolute bg-editor-light-effect inset-0 w-full h-full pointer-events-none z-20 bg-cover bg-no-repeat mix-blend-soft-light" />
    </div>
  )
}
