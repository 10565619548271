import { ReactNode, useState } from "react"
import { Popover } from "./Popover";
import clsx from "clsx";

type TooltipProps = {
  children: ReactNode;
  content: string | undefined;
}

export function Tooltip({ children, content }: TooltipProps) {
  const [isHovered, setIsHovered] = useState(false);
 
  return (
    <div 
      className="relative" 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {content &&
        <Popover role="tooltip" className={clsx("absolute top-0 text-sm w-fit whitespace-nowrap -translate-y-[calc(100%+6px)] transition-opacity ease-linear duration-300 py-1 px2", isHovered ? 'opacity-100' : 'opacity-0')}>
          {content}
        </Popover>
      }
    </div>
  )
}