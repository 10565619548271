import { CollapseAllIcon } from "../assets/CollapseAll";
import { Chips } from "../ui-kit/Chips";
import { Input } from "../ui-kit/Input";
import { TabContentHeader } from "../ui-kit/TabContentHeader";
import { DocTree, FnTree, fns as functions } from "./editor";
import searchIcon from '../assets/Search.svg';
import { useEffect, useMemo, useState } from "react";
import { TabContentBody } from "../ui-kit/TabContentBody";
import { useExpand } from "../hooks/useExpand";

export function Functions({ }) {
  const { expandedFunctions, setExpandedFunctions, setExpandAllTrigger } = useExpand();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setExpandedFunctions(0);
    if (searchValue) {
      setExpandAllTrigger(prev => searchValue ? prev + 1 : 0);
    } else {
      setExpandAllTrigger(0);
    }
  }, [searchValue]);

  const filterFunctions = (functions: FnTree[], query: string): FnTree[] => {
    return functions
      .map((_function) => {
        if (
          (_function.type === 'leaf' && (_function.name.toLowerCase().includes(query) || _function.value.name.toLowerCase().includes(query)))
        ) {
          return _function;
        } else if (_function.type === 'node') {
          const filteredChildren = filterFunctions(_function.children, query);
          if (filteredChildren.length > 0 || _function.name.toLowerCase().includes(query)) {
            return { ..._function, children: filteredChildren };
          }
        }
        return null;
      })
      .filter((_function): _function is FnTree => _function !== null);
  };

  const filteredFunctions = useMemo(
    () => searchValue ? filterFunctions(functions, searchValue.toLowerCase()) : functions,
    [searchValue, functions],
  );

  return (
    <>
      <TabContentHeader>
        <Input
          placeholder="Search functions..."
          leading={<img src={searchIcon} className="w-6 h-6" />}
          value={searchValue}
          onChange={setSearchValue}
          onClear={() => setSearchValue('')}
        />
        <div className="flex self-stretch justify-between items-start">
          <Chips label="All categories" />
          <Chips
            isDisabled={expandedFunctions === 0}
            onClick={() => setExpandedFunctions(0)}
            leading={<CollapseAllIcon variant={expandedFunctions === 0 ? 'disabled' : 'default'} />} className="!px-2 !py-[5px]"
          />
        </div>
      </TabContentHeader>
      <TabContentBody>
        <div className="flex flex-col items-start self-stretch rounded-md h-fit">
          {filteredFunctions.map((_function: FnTree) => (
            <DocTree key={_function.name} node={_function} />
          ))}
        </div>
      </TabContentBody>
    </>
  )
}
