import { AriaRole } from "react";

type CrossProps = {
  variant?: 'default' | 'danger';
  onClick?: VoidFunction;
  role?: AriaRole | undefined;
  className?: string;
}
export function Cross({ variant = 'default', onClick, role, className }: CrossProps) {
  return (
    <svg onClick={onClick} role={role} className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path data-variant={variant} className="data-[variant=default]:fill-font data-[variant=danger]:fill-danger" d="M7.9999 9.06086L4.797 12.2638C4.65618 12.4046 4.47937 12.475 4.26657 12.475C4.05377 12.475 3.87696 12.4046 3.73614 12.2638C3.59531 12.1229 3.5249 11.9461 3.5249 11.7333C3.5249 11.5205 3.59531 11.3437 3.73614 11.2029L6.93904 7.99999L3.73614 4.79709C3.59531 4.65627 3.5249 4.47946 3.5249 4.26666C3.5249 4.05386 3.59531 3.87705 3.73614 3.73623C3.87696 3.59541 4.05377 3.52499 4.26657 3.52499C4.47937 3.52499 4.65618 3.59541 4.797 3.73623L7.9999 6.93913L11.2028 3.73623C11.3436 3.59541 11.5204 3.52499 11.7332 3.52499C11.946 3.52499 12.1228 3.59541 12.2637 3.73623C12.4045 3.87705 12.4749 4.05386 12.4749 4.26666C12.4749 4.47946 12.4045 4.65627 12.2637 4.79709L9.06077 7.99999L12.2637 11.2029C12.4045 11.3437 12.4749 11.5205 12.4749 11.7333C12.4749 11.9461 12.4045 12.1229 12.2637 12.2638C12.1228 12.4046 11.946 12.475 11.7332 12.475C11.5204 12.475 11.3436 12.4046 11.2028 12.2638L7.9999 9.06086Z" fill="white" />
    </svg>
  )
}
