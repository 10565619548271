type TabProps = {
  label: string;
  isActive: boolean;
  onClick: VoidFunction;
}

export function Tab({ label, isActive, onClick }: TabProps) {
  return (
    <div 
      className="flex px-4 relative items-center cursor-pointer capitalize justify-center flex-1 gap-2 font-youth text-sm font-black data-[active=true]:text-font data-[active=false]:text-font-variant"
      data-active={isActive}
      onClick={onClick}
    >
      {label}
    </div>
  )
}
