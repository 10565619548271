import { HexString } from "@mass-money/sdk/web";
import { useMemo, useState } from "react";
import loaderIcon from '../assets/Loader.svg';
import hourGlassIcon from '../assets/HourGlass.svg';
import checkCircleIcon from '../assets/CheckCircle.svg';
import { Caret } from "../assets/Caret";
import saveIcon from "../assets/Save.svg";
import clsx from "clsx";
import Editor from 'react-monaco-editor';
import { DeleteOrder } from "./DeleteOrder";
import { CodeDisplay } from "./CodeDisplay";

export type Operation = {
  calldata: HexString;
  code: string;
  date: Date;
}

type OrderEntryProps = {
  status: 'processing' | 'scheduled' | 'completed';
  operations: Operation[];
}

export function OrderEntry({ operations, status }: OrderEntryProps) {
  const [isOpen, setIsOpen] = useState(false);

  const title = useMemo(() => {
    switch (status) {
      case 'completed':
        return { icon: checkCircleIcon, label: 'Completed' };
      case 'processing':
        return { icon: loaderIcon, label: 'Processing' };
      case 'scheduled':
        return { icon: hourGlassIcon, label: 'Scheduled' };
    }
  }, [status]);

  return (
    <div className="flex flex-col w-full h-full">
      <div data-open={isOpen} onClick={() => setIsOpen(prev => !prev)} className="flex data-[open=true]:bg-surface-transparent-active bg-surface-transparent p-3 items-center cursor-pointer gap-2 self-stretch">
        <div className="flex items-center gap-2 flex-1 font-medium font-youth text-sm">
          <img src={title.icon} />
          <span>{title.label}</span>
        </div>
        <span className="text-font-variant flex-1 text-right">
          {`${operations.length} operation${operations.length === 1 ? '' : 's'}`}
        </span>
        <Caret
          color="variant"
          className={clsx(
            "transition-all ease-out duration-[160ms] w-4 h-4",
            isOpen ? "rotate-180" : ""
          )}
        />
      </div>
      {isOpen && (
        operations.map(({ calldata, code, date }, index) => (
          <div key={index} className="flex p-3 items-start gap-2 self-stretch">
            <span className="font-youth pl-[5px] pr-1 text-sm font-medium text-font-variant">
              {"->"}
            </span>
            <div className="flex flex-col flex-1 w-full overflow-hidden items-start justify-center gap-3 h-full">
              <span className="font-youth text-sm font-medium self-stretch w-full break-words line-clamp-2 h-full">
                {calldata}
              </span>
              <CodeDisplay code={code} />
              <div className="flex items-center gap-3 self-stretch text-sm text-font-variant">
                <span className="flex-1">{date.toISOString().split('T')[0]}</span>
                {status === 'scheduled' && <DeleteOrder />}
                <img role="button" src={saveIcon} />
              </div>
            </div>
          </div>
        ))
      )}
      <div className="h-0.5 w-full bg-border" />
    </div>
  );
}
