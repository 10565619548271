import { useEffect, useState } from "react";
import { Tab } from "./Tab";

type TabBarProps = {
  tabs: string[];
  activeTab: string;
  onChange: (newTab: string) => void;
}

export function TabBar({ activeTab, tabs, onChange }: TabBarProps) {
  const [indicatorPosition, setIndicatorPosition] = useState(0);

  useEffect(() => {
    const activeIndex = tabs.indexOf(activeTab);
    setIndicatorPosition(activeIndex);
  }, [activeTab, tabs]);

  return (
    <div className="flex py-3 gap-1 items-center self-stretch relative z-20">
      {tabs.map(tab =>
        <Tab key={tab} label={tab} isActive={activeTab === tab} onClick={() => onChange(tab)} />
      )}
      <div
        className="absolute flex justify-center -bottom-2.5 transition-transform ease-out duration-[160ms]"
        style={{
          transform: `translateX(calc(${indicatorPosition * 100}%))`,
          width: `${100 / tabs.length}%`,
        }}
      >
        <div className="rounded-full box-content border-t-2 border-x-2 border-b-4 border-border bg-accent w-8 h-1" />
      </div>
    </div>
  )
}
