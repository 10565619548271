import { useMemo } from "react";
import { atom, useRecoilState } from "recoil";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";

type ValueToInject = {
  value: string;
  type?: 'namespace' | 'function';
}

const editorInjectorAtom = atom<ValueToInject>({
  key: 'editorInjector',
  default: { value: '' },
  effects: [
    ({ onSet, setSelf }) => {
      onSet(() => {
        setTimeout(() => {
          // reset injected value after set
          setSelf({ value: '' })
        }, 10);
      })
    }
  ]
});

function isNamespaceMissing(code: string, namespace: string): boolean {
  const regexValidUse = new RegExp(`^\\s*${namespace}\\s*$`, 'gm');
  
  const lines = code.split('\n');
  for (const line of lines) {
    if (regexValidUse.test(line.trim())) {
      return false;
    }
  }
  return true;
}

export function useInject() {
  const [valueToInject, setValueToInject] = useRecoilState(editorInjectorAtom);
  const code = useReadLocalStorage<string>('code');

  const _valueToInject = useMemo(() => {
    if (code) {
      if (valueToInject.type === 'function') {
        const namespaceSegments = valueToInject.value.split('.');
        let usedNamespace = '';

        for (let i = 0; i < namespaceSegments.length - 1; i++) {
          const currentNamespace = namespaceSegments.slice(0, i + 1).join('.');
          if (!isNamespaceMissing(code, `#use ${currentNamespace};`)) {
            usedNamespace = currentNamespace;
          }
        }

        const trimmedValue = valueToInject.value.startsWith(usedNamespace) && usedNamespace
          ? valueToInject.value.slice(usedNamespace.length + 1)
          : valueToInject.value;

        return {
          ...valueToInject,
          value: trimmedValue,
        };
      } else {
        if (isNamespaceMissing(code, valueToInject.value)) {
          return valueToInject;
        }
      }
    }
  }, [valueToInject]);

  return { inject: setValueToInject, valueToInject: _valueToInject };
}
