type SwitchProps = {
  onChange?: VoidFunction;
  isActive?: boolean;
}

export function Switch({ isActive = false, onChange }: SwitchProps) {
  return(
    <div onClick={onChange} data-active={isActive} role="checkbox" className="flex cursor-pointer rounded-full transition-all ease-out duration-[160ms] border-t-[3px] border-[1.5px] border-border pt-[1.5px] bg-surface-transparent data-[active=true]:bg-surface-transparent-active data-[active=false]:pr-[21px] data-[active=true]:pl-[21px]">
      <div className="items-end flex justify-center px-[1.5px] pb-[3px] -mt-[1.5px]">
        <div data-active={isActive} className="h-[19.5px] w-[19.5px] transition-all ease-out duration-[160ms] rounded-full bg-surface data-[active=true]:bg-accent border-border -mx-[1.5px] -mb-[3px] border-b-[3px] border-[1.5px]" />
      </div>
    </div>
  )
}