import { ReactNode } from "react"

type MainProps = {
  children: ReactNode;
}

export function Main({ children }: MainProps) {
  return (
    <main className="flex p-3 flex-col gap-3 items-start self-stretch flex-1 rounded-t-2xl bg-surface border-t-2 border-x-2 border-border">
      {children}
    </main>
  )
}
