import { Main } from "../ui-kit/Main";
import { MainContent } from "../ui-kit/MainContent";
import { MainScreen } from "../ui-kit/MainScreen";
import clipboardIcon from '../assets/Clipboard.svg';
import pasteIcon from '../assets/Paste.svg';
import { Feedback, FeedbackProps } from "../ui-kit/Feedback";
import { useEffect, useState } from "react";
import { parseExplorerLink, useHash, useNet } from "../utils/utils";
import { decompileCalldataToCode, HexString } from "@mass-money/sdk/web";
import { CodeDisplay } from "./CodeDisplay";
import clsx from "clsx";

export function Analyze() {
  const [calldata, setCalldata] = useState('');
  
  const net = useNet();
  const [hash] = useHash();
  const vaultAddress = hash.replace("#", "") as HexString;
  
  const [feedback, setFeedback] = useState<FeedbackProps>({
    status: 'neutral',
    title: 'Paste something to decompile it...',
  });

  const decompile = async (_calldata: HexString) => {
    if (!net) {
      return;
    }
    setFeedback({
      status: 'accent',
      title: 'Decompiling...',
    });
    try {
      const { code } = await decompileCalldataToCode(
        {
          rpcUrl: net?.rpc,
          vaultAddress,
        }, 
        _calldata,
      );

      setFeedback({
        title: 'Decompilation successfull',
        status: 'success',
        description: (
          <CodeDisplay code={code}/>
        )
      });
    } catch (e) {
      setFeedback({
        title: 'Decompilation failed',
        status: 'error',
        description: (e as any).message,
      });
    }
  }

  const pasteCalldata = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const calldata = text.startsWith("https://")
        ? await parseExplorerLink(text)
        : text;
      setCalldata(calldata);
      await decompile(calldata as HexString);
    } catch (e) {
      console.error("Failed to read clipboard: ", e);
    }
  };

  useEffect(() => {
    const handlePaste = async (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'v' && net) {
        event.preventDefault();
        await pasteCalldata();
      }
    };

    window.addEventListener('keydown', handlePaste);
    return () => {
      window.removeEventListener('keydown', handlePaste);
    };
  }, [net]);

  return (
    <Main>
      <MainContent className="h-full">
        <MainScreen className="items-center justify-center flex-col gap-3 w-full overflow-hidden">
          {calldata
            ? (
              <>
                <div role="button" onClick={pasteCalldata} className="flex py-2 px-3 justify-center items-center font-medium text-sm self-stretch gap-2 rounded bg-[#EBFF4214]">
                  <img className="w-h h-4" src={clipboardIcon} />
                  <span className="flex-1">Paste calldata or explorer link here</span>
                  <span className="font-youth text-accent">Paste from clipboard</span>
                </div>
                <div className="flex-1 self-stretch w-full break-all h-full overflow-auto hide-scrollbars">{calldata}</div>
              </>
            )
            : (
              <div role="button" onClick={pasteCalldata} className="flex relative flex-col gap-2 font-medium justify-center items-center py-12 px-20 rounded-3xl bg-[#EBFF4214]">
                <img src={clipboardIcon} />
                <span className="text-center">Paste calldata or<br />explorer link here</span>
                <span className="font-youth text-accent">Paste from clipboard</span>
                <img src={pasteIcon} className="absolute bottom-[30px]" />
              </div>
            )}
        </MainScreen>
        <Feedback {...feedback} className={clsx(feedback.status === 'success' && 'h-2/3')} />
      </MainContent>
    </Main>
  )
}
